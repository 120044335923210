import React, { useEffect, useState } from "react"
import { Link, navigate } from "gatsby"
import "./style.scss"

const DropdownMenu = () => {
  const [activeMenu, setActiveMenu] = useState('sales');

  return (
    <div className={ `dropdown-menu ${ activeMenu }-menu` }>
      <div className="options">
        <ul>
          <li className="sales-option">
            <button onMouseEnter={e => setActiveMenu('sales')} onClick={e => navigate('/sales')}>Sales</button>
          </li>
          <li className="marketing-option">
            <button onMouseEnter={e => setActiveMenu('marketing')} onClick={e => navigate('/marketing')}>Marketing</button>
          </li>
          <li className="managers-option">
            <button onMouseEnter={e => setActiveMenu('managers')} onClick={e => navigate('/manager')}>Managers</button>
          </li>
          <li className="agencies-option">
            <button onMouseEnter={e => setActiveMenu('agencies')} onClick={e => navigate('/agencies')}>Agencies</button>
          </li>
          <li className="vendors-option">
            <button onMouseEnter={e => setActiveMenu('vendors')} onClick={e => navigate('/vendors')}>Vendors</button>
          </li>
        </ul>
      </div>
      <div className="features">
        <div className="sales-feature feature">
          <div className="content">
            <h2>Sales Features</h2>
            <p>Your sales process is bogged down by tedious and time-consuming activities that take away from your ability to convert. With GlassHive, you can break through those barriers and get the freedom you need to carry out sales activities that help you close.</p>
            <Link to="/sales" className="btn blue">
              <span className="btn__mask"></span>
              <span className="btn__text">
                Learn More{" "}
                <img src={require("../../svgs/nav/right-arrow.svg")} alt="arrow" />
              </span>
            </Link>
          </div>
        </div>
        <div className="marketing-feature feature">
          <div className="content">
            <h2>Marketing Features</h2>
            <p>Your very own IT marketing hive, complete with everything you need to create super sweet IT marketing. From intuitive content creation features to powerful automation and visibility features, GlassHive takes your marketing to the next level.</p>
            <Link to="/marketing" className="btn blue">
              <span className="btn__mask"></span>
              <span className="btn__text">
                Learn More{" "}
                <img src={require("../../svgs/nav/right-arrow.svg")} alt="arrow" />
              </span>
            </Link>
          </div>
        </div>
        <div className="managers-feature feature">
          <div className="content">
            <h2>Manager Features</h2>
            <p>Sales and marketing management is like a game of chess. It takes years of detailed analysis and sound strategy. GlassHive provides you the immediate success formula and powerful features and data to consistently win.</p>
            <Link to="/manager" className="btn blue">
              <span className="btn__mask"></span>
              <span className="btn__text">
                Learn More{" "}
                <img src={require("../../svgs/nav/right-arrow.svg")} alt="arrow" />
              </span>
            </Link>
          </div>
        </div>
        <div className="agencies-feature feature">
          <div className="content">
            <h2>Agency Features</h2>
            <p>GlassHive gives your agency a platform to carry out truly influential digital marketing. With complete visibility, management, data, and analytics, you can become the powerhouse you were meant to be.</p>
            <Link to="/agencies" className="btn blue">
              <span className="btn__mask"></span>
              <span className="btn__text">
                Learn More{" "}
                <img src={require("../../svgs/nav/right-arrow.svg")} alt="arrow" />
              </span>
            </Link>
          </div>
        </div>
        <div className="vendors-feature feature">
          <div className="content">
            <h2>Vendor Features</h2>
            <p>GlassHive revolutionizes partner enablement by placing directly into your partner’s workflow. In addition to automated on-demand partner performance visibility, you will have the ability to share marketing and sales assets with them at unlimited scale.{" "}</p>
            <Link to="/vendors" className="btn blue">
              <span className="btn__mask"></span>
              <span className="btn__text">
                Learn More{" "}
                <img src={require("../../svgs/nav/right-arrow.svg")} alt="arrow" />
              </span>
            </Link>
          </div>
        </div>
      </div>
    </div>
  )
}

export default DropdownMenu
