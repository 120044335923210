import React, { useState, useEffect } from "react";
import Loader from "../Loader";
import ShinyButton from "../ShinyButton";

import "./style.scss";

const NewsletterSignup = () => {
    const [submittingForm, setSubmittingForm] = useState(false);
    const [submissionError, setSubmissionError] = useState(false);
    const [submitSuccessful, setSubmissionSuccessful] = useState(false);
    const [relativeUrl, setRelativeUrl] = useState("/");

    useEffect(() => {
        let relativeURL = window.location.pathname + window.location.search;

        if (!relativeURL.endsWith("/")) {
            relativeURL += "/";
        }
        setRelativeUrl(relativeURL);
    }, []);

    const submitForm = () => {
        setSubmittingForm(true);
        setSubmissionError(false);

        const formName = document.getElementById("newsletter-signup");

        fetch(relativeUrl + "#thanks", {
            method: "POST",
            body: new FormData(formName),
        }).then(res => {
            if (res.status === 200) {
                setSubmissionSuccessful(true);
                setSubmissionError(false);
                setSubmittingForm(false);
            } else {
                setSubmittingForm(false);
                setSubmissionError(true);
            }
        });
    };

    return (
        <div className="newsletter-signup">
            <div>
                <h6>GlassHive Community</h6>
                <p>
                    Sign up to our email list for marketing tips, resources, and
                    company updates delivered straight to your inbox.
                </p>
                {!submitSuccessful && !submittingForm && (
                    <form
                        id="newsletter-signup"
                        className="newsletter-signup NewsletterSignup"
                        method="POST"
                        name="newsletter-signup"
                        encType="multipart/form-data"
                        netlify="true"
                        netlify-honeypot="bot-field"
                        data-category="newsletter-signup"
                        onSubmit={e => {
                            e.preventDefault();
                            submitForm();
                        }}
                    >
                        {submissionError && (
                            <p id="error-msg" className="show-error">
                                Looks like there was a problem submitting your
                                form. Please ensure you entered a valid email
                                and try again.
                            </p>
                        )}
                        <input
                            type="hidden"
                            name="form-name"
                            value="newsletter-signup"
                        />
                        <div className="email-field">
                            <input
                                name="email"
                                placeholder="Enter your email"
                            />
                        </div>
                        <input type="hidden" name="bot-field" id="bot" />
                        <div className="submit-button-container">
                            <ShinyButton
                                tagType="button"
                                className="btn pink white-text"
                            >
                                Subscribe
                            </ShinyButton>
                        </div>
                    </form>
                )}
                {submittingForm && (
                    <div className="submitting-notice">
                        <Loader borderColor="#f30070" />
                    </div>
                )}
                {submitSuccessful && (
                    <div className="contact-thank-you reach" id="thanks">
                        <p>Thank you for signing up for our newsletter!</p>
                    </div>
                )}
            </div>
        </div>
    );
};

export default NewsletterSignup;
