import React from 'react';
import { slide as Menu } from 'react-burger-menu';
import './style.scss';
import { throwSignupConversionEvent } from '../../scripts/utils';

class Burger extends React.Component {
    showSettings(event) {
        event.preventDefault();
    }

    render() {
        return (
            <Menu right className="navbar-burger">
                <a className="menu-item" href="/sales">
                    Sales
                </a>
                <a className="menu-item" href="/marketing">
                    Marketing
                </a>
                <a className="menu-item" href="/manager">
                    Managers
                </a>
                <a className="menu-item" href="/agencies">
                    Agencies
                </a>
                <a className="menu-item" href="/vendors">
                    Vendors
                </a>
                <a className="menu-item" href="/pricing">
                    Pricing
                </a>
                <a className="menu-item" href="/resources">
                    Resources
                </a>
                <a className="menu-item" href="/reviews">
                    Reviews
                </a>
                <a className="menu-item" href="https://app.glasshive.com">
                    Log in
                </a>
                <a
                    className="menu-item"
                    href="#"
                    onClick={() =>
                        throwSignupConversionEvent(
                            'https://app.glasshive.com/Account/Login?s=signup'
                        )
                    }
                >
                    Start Free Trial
                </a>
            </Menu>
        );
    }
}

export default Burger;
