import React from 'react';
import './style.scss';

export default function Socket() {
    return (
        <div className="socket">
            <p className="footer-text white-text">
                Copyright © {new Date().getFullYear()} GlassHive.
            </p>
            <div className="right-side">
                <a
                    href="https://app.glasshive.com/Account/TermsOfService"
                    target="_blank"
                    className="white-text"
                >
                    Terms of Use
                </a>
                <span className="divider">|</span>
                <a
                    href="https://app.glasshive.com/Account/PrivacyPolicy"
                    target="_blank"
                    className="white-text"
                >
                    Privacy Policy
                </a>
                <span className="divider">|</span>
                <a
                    href="https://status.glasshive.com/"
                    target="_blank"
                    className="white-text"
                >
                    Status
                </a>
            </div>
        </div>
    );
}
