import React from 'react';
import { throwSignupConversionEvent } from '../../scripts/utils';
import './style.scss';

const Footer = () => (
    <div className="mobile-btns">
        <a href="https://app.glasshive.com" className="btn blue">
            <span className="btn__mask"></span>
            <span className="btn__text">Log In</span>
        </a>
        <a
            onClick={() =>
                throwSignupConversionEvent(
                    'https://app.glasshive.com/Account/Login?s=signup'
                )
            }
            className="btn pink"
        >
            <span className="btn__mask"></span>
            <span className="btn__text">Start Free Trial</span>
        </a>
    </div>
);

export default Footer;
