import React from "react";

const ShinyButton = ({ href, className, children, onClick }) => {

    function handleClick() {
        if (onClick) {
            onClick();
        }
    }

    if (href) {
        return (
            <a href={href} className={className}>
                <span className="btn__mask"></span>
                <span className="btn__text">{children}</span>
            </a>
        );
    } else {
        return (
            <button className={className} type="submit" onClick={handleClick}>
                <span className="btn__mask"></span>
                <span className="btn__text">{children}</span>
            </button>
        );
    }
};

export default ShinyButton;
