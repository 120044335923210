export function throwSignupConversionEvent(href) {
    let script = document.createElement('script');
    script.type = 'text/javascript';
    script.async = true;

    script.onload = () => {
        window.location.href = href;
    };

    script.onerror = () => {
        window.location.href = href;
    };

    script.src =
        'https://tag.simpli.fi/sifitag/c6c18c40-11df-013b-a29f-0cc47abd0334';

    document.getElementsByTagName('head')[0].appendChild(script);
}
