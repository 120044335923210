export default (function (w, d, undefined) {
    var clientId = 166;
    var listId = 10672;
    var honeyFormSettings = [];
    var formCategory = { ProQuestionnaire: 12724, NewsletterSignup: 12929 };

    function setup() {
        var formsOnPage = document.querySelectorAll("form");

        if (formsOnPage) {
            for (var i = 0; i < formsOnPage.length; i++) {
                var currentForm = formsOnPage[i];
                for (const category in formCategory) {
                    if (currentForm.classList.contains(category)) {
                        listId = formCategory[category];
                    }
                }

                honeyFormSettings.push(
                    new FormSetting(
                        currentForm.id,
                        currentForm.dataset.category
                    )
                );
            }

            addHoneyScripts();
        }
    }

    function FormSetting(formSelector, formType) {
        return {
            selector: "#" + formSelector,
            listId: listId,
            formType: formType,
            mappings: {
                firstName: ".name-field input",
                email: ".email-field input",
                company: "company-field input",
                title: "title-field input",
                comment: ".comment-field textarea",
            },
        };
    }

    function addHoneyScripts() {
        w._honeySettings = {
            clientId: clientId,
            websiteUrl: window.location.href,
            forms: honeyFormSettings,
        };
    }

    return {
        setup,
    };
})(window, document);
