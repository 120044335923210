import React, { useState, useEffect } from "react"

import "./style.scss"

const Banner = () => {
    const [visible, setVisible] = useState(false);

    const closeBanner = () => {
        localStorage.setItem('gh_hide_banner', true);
        setVisible(false);
    };

    useEffect(() => {
        if (typeof localStorage !== 'undefined') {
            setVisible(localStorage.getItem('gh_hide_banner') !== "true");
        }
    }, []);

    return (
        <div className={`banner ${visible ? "" : "hidden"}`}>
            <div className="close-widget" onClick={() => {closeBanner()}}>
                <img className="close-svg" src={require("../../images/banner/x.svg")} alt="close" />
            </div>
            <div className="content">
                <img className="logo" src={require("../../images/banner/gh-logo.png")} alt="logo" />
                <div className="header">We're changing names!</div>
                <div className="paragraph">Honey CRM is changing its name to GlassHive. As our product is expanding, so is our vision of what we want to offer.</div>
                <div className="paragraph">We aim to evolve beyond our capabilities, and help your company's service delivery by building a work OS designed for MSPs. CRM is just a small piece of what we do. GlassHive gives us endless possibilities to do anything we can imagine.</div>
            </div>
        </div>
    )
}

export default Banner
