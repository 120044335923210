import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import Burger from '../HamburgerMenu';
import CookieConsent from 'react-cookie-consent';
import { Link } from 'gatsby';
import DropdownMenu from '../DropdownMenu';
import GlassHiveLogoDark from '../GlassHiveLogoDark';
import GlassHiveLogoLight from '../GlassHiveLogoLight';
import { Helmet } from 'react-helmet';
import ShinyButton from '../ShinyButton';
import { throwSignupConversionEvent } from '../../scripts/utils';

import './style.scss';

const honeyIntegration =
    typeof window !== 'undefined'
        ? require('../../scripts/honey-form-integration.js')
        : null; // TODO: Does this need to update?
const honeyValidation =
    typeof window !== 'undefined'
        ? require('../../scripts/honey-form-validation.js')
        : null; // TODO: Does this need to update?

const Header = ({ navTheme, siteTitle, headerVersion }) => {
    const [transparent, setTransparent] = useState(true);
    const [scrollTop, setScrollTop] = useState(0);
    const [hideNav, setHideNav] = useState(false);

    const getScrollTop = () => {
        return window.pageYOffset || document.documentElement.scrollTop;
    };

    useEffect(() => {
        setScrollTop(getScrollTop());

        window.addEventListener('scroll', function () {
            var currentScrollTop = getScrollTop();

            if (window.scrollY === 0) {
                setTransparent(true);
                setHideNav(false);
            } else {
                setTransparent(false);
                setHideNav(currentScrollTop > scrollTop);
            }

            setScrollTop(currentScrollTop);
        });

        honeyIntegration.default.setup();
        honeyValidation.default.run();
    });

    return (
        <>
            <Helmet>
                <link
                    href="https://fonts.googleapis.com/css2?family=Nunito:wght@400;600;700;800"
                    rel="stylesheet"
                ></link>
                <link
                    href="https://fonts.googleapis.com/css2?family=Roboto:wght@400;700"
                    rel="stylesheet"
                ></link>
            </Helmet>
            <header
                className={`fixed ${transparent ? '' : 'end-transparency'} ${
                    hideNav ? 'hide-nav' : 'show-nav'
                }`}
            >
                <CookieConsent
                    cookieName="myAwesomeCookieName2"
                    style={{ background: '#fff', justifyContent: 'Center' }}
                    buttonStyle={{
                        color: '#fff',
                        fontSize: '13px',
                        background: '#ff3975',
                        borderRadius: '20px',
                        padding: '10px 20px',
                    }}
                    contentStyle={{
                        color: '#4a4a4a',
                        textTransform: 'none',
                        flex: 'initial',
                        fontSize: '13px',
                    }}
                    expires={150}
                    acceptOnScroll={true}
                    acceptOnScrollPercentage={10}
                >
                    This website uses cookies to enhance the user experience.
                </CookieConsent>
                <div
                    className={'mainHeader navbar ' + navTheme}
                    role="navigation"
                >
                    <div className="navbar-brand">
                        <Link to="/" className="navbar-item">
                            <img
                                src={
                                    navTheme === 'light'
                                        ? require('../../images/nav/white-gh-new-header-logo.png')
                                        : require('../../images/nav/gh-new-header-logo.png')
                                }
                                alt="glasshive logo"
                            />
                        </Link>
                        <Burger className="burger-container" />
                    </div>
                    <div id="navbarBasicExample" className="navbar-menu">
                        <div className="navbar-start">
                            <div className="dropdown-container">
                                <button className="navbar-item features-nav">
                                    Features
                                </button>
                                <DropdownMenu />
                            </div>
                            <Link to="/pricing" className="navbar-item">
                                Pricing
                            </Link>
                            <Link to="/resources" className="navbar-item">
                                Resources
                            </Link>
                            <Link to="/reviews" className="navbar-item">
                                Reviews
                            </Link>
                            <Link to="/careers" className="navbar-item">
                                Careers
                            </Link>
                        </div>
                        <div className="navbar-end">
                            <div className="navbar-item">
                                <div className="buttons">
                                    <ShinyButton
                                        className="btn blue first"
                                        href="https://app.glasshive.com"
                                    >
                                        Log in
                                    </ShinyButton>
                                    <ShinyButton
                                        className="btn pink margin-left-50 create-free-account-button"
                                        onClick={() =>
                                            throwSignupConversionEvent(
                                                'https://app.glasshive.com/Account/Login?s=signup'
                                            )
                                        }
                                    >
                                        Start Free Trial
                                    </ShinyButton>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </header>
        </>
    );
};

Header.propTypes = {
    siteTitle: PropTypes.string,
};

Header.defaultProps = {
    siteTitle: ``,
};

export default Header;
